import { AdConfigDataProps } from '@sport1/types/web'
import { MappedTrackingData } from '@/utils/tracking/TrackingProvider'
import { Type } from '@/types/video'
import { SDGPublisherVastProps, VideoPlayerAdProps, VideoZoneType } from '@/types/vastAd'
import { IJWMedia } from '@/types/jwplayer'
import { VideoPlaylistItem } from '@/helpers/videoHelper/prepareVideoPlaylist'

/**
 * Flags to overwrite the API
 * API and these flags need to be true to be active
 */

export const adpodEnabled = true
export const prerollEnabled = true
export const midrollEnabled = false
export const postrollEnabled = false

export const MAX_WAIT_TIME = 1000

export const zones: Record<Type | string, VideoZoneType> = {
    VoD: 'vod',
    liveStream: 'live',
    eventStream: 'live',
    undefined: 'vod',
}

export type AdConfig = AdConfigDataProps & { adpodEnabled: boolean }

export type VastUrlCoreProps = {
    ad?: Partial<AdConfig>
    trackingData?: MappedTrackingData
}

export type getVastUrlProps = SDGPublisherVastProps & VastUrlCoreProps

export type ConfigureVastProps = VastUrlCoreProps & {
    callback: (adConfig: VideoPlayerAdProps) => void
    playlist: Partial<VideoPlaylistItem & IJWMedia>[]
    type?: Type
    duration?: number
}

export interface ProcessPlaylistAds extends VastUrlCoreProps {
    playlist: Partial<VideoPlaylistItem & IJWMedia>[]
    tag: string
    isLivestream: boolean
}
