import getVideoLength from '../video/getVideoLength'
import { getPrerollsCount } from './utils'
import {
    ConfigureVastProps,
    getVastUrlProps,
    MAX_WAIT_TIME,
    midrollEnabled,
    adpodEnabled,
    postrollEnabled,
    prerollEnabled,
    ProcessPlaylistAds,
    VastUrlCoreProps,
    zones,
    AdConfig,
} from './vastConfig.types'
import { Type } from '@/types/video'
import { isPureActive } from '@/components/ThirdParty/Piano/util'
import { SDGPublisherVastResult, VastType, VideoPlayerAdProps, VideoZoneType } from '@/types/vastAd'
import Config from '@/utils/Config'

const configureAd = (ad: VastUrlCoreProps['ad']): VastUrlCoreProps['ad'] => {
    if (!ad) {
        return
    }
    return {
        ...ad,
        adpodEnabled: adpodEnabled && (ad.midrollEnabled || ad.adpodEnabled),
        prerollEnabled: prerollEnabled && ad.prerollEnabled,
        midrollEnabled: midrollEnabled && ad.midrollEnabled,
        postrollEnabled: postrollEnabled && ad.postrollEnabled,
    }
}

const checkShowAd = (propName: keyof AdConfig, adObject: VastUrlCoreProps['ad']) => {
    if (adObject && Object.hasOwn(adObject ?? {}, propName)) {
        if (adObject[propName]) {
            return '1'
        }
        return '0'
    }
    return '1'
}

/**
 * pkv1: adUnit3
 * pkv2: adUnit4
 * pkv3: team
 * pkv4: contentType
 * pkv5: video_program
 *
 * if something's not set, fills up
 */
const createTargetingQueryParameter = ({ ad, trackingData }: VastUrlCoreProps) => {
    const targetingValues: Array<string | undefined> = [
        ad?.targeting?.adUnit3,
        ad?.targeting?.adUnit4,
        ad?.targeting?.team,
        ad?.targeting?.contentType,
        trackingData?.['video_program'],
    ]

    if (!targetingValues.filter(el => el).length) {
        return ''
    }

    return `${encodeURIComponent(
        targetingValues
            .map((v, i) => ({
                pkvNumber: i + 1,
                value: v,
            }))
            .filter(el => el.value)
            .map(el => `pkv${el.pkvNumber}=${encodeURIComponent(String(el.value).toLowerCase())}`)
            .join('&')
    )}`
}

/**
 * MUST only be called client side
 */
export const getVastUrl = ({ ad, videozone, trackingData, callback }: getVastUrlProps) => {
    if (typeof window === 'object') {
        let calledBack = false
        let timer: number
        ad = configureAd(ad)

        /**
         * in case the ad server is down: just start the video
         */
        const timeout = setTimeout(() => {
            calledBack = true
            callback({})
        }, MAX_WAIT_TIME)

        window.SDG?.cmd.push(async function () {
            clearTimeout(timeout)
            if (calledBack) {
                return
            }
            calledBack = true
            const tags: SDGPublisherVastResult = await Promise.race([
                window?.SDG?.Publisher?.generateVastUrlsPromise?.({
                    preroll: ad?.prerollEnabled,
                    midroll: ad?.midrollEnabled,
                    adpod: ad?.adpodEnabled,
                    videozone,
                }),
                new Promise(resolve => {
                    timer = window.setTimeout(() => {
                        resolve({})
                    }, 1000)
                }),
            ])
            clearTimeout(timer)
            if (videozone === 'live') {
                /**
                 * Ströer ads a placeholder `~sport1kvs~`, we replace this
                 * with an url encoded query string with of our targeting
                 */
                const targeting = createTargetingQueryParameter({ ad, trackingData })
                Object.keys(tags)?.forEach(key => {
                    const tag = tags[key as VastType]
                    if (tag) {
                        tags[key as VastType] = tag.replace('~sport1kvs~', targeting)
                    }
                })
            }
            // eslint-disable-next-line no-console
            console.debug(
                'Ads: SDG?.Publisher?.generateVastUrlsPost({ bumper, preroll, midroll, postroll, videozone })',
                {
                    preroll: ad?.prerollEnabled,
                    midroll: ad?.midrollEnabled,
                    adpod: ad?.adpodEnabled,
                    videozone,
                },
                tags
            )

            callback?.(tags)
        })
    }
}

export const buildVideoZone = (type: Type | undefined): VideoZoneType => zones[String(type)]

const createAdConfig = (isLivestream: boolean): VideoPlayerAdProps => {
    /** frequency 0 on Livestream, otherwise the prerolls will endlessly play
     *  default values of rules are frequency 1 and startOn 1
     *  for more information https://docs.jwplayer.com/players/reference/advertising-config-ref */
    const rules = isLivestream ? { frequency: 0, startOn: 0 } : undefined

    return {
        advertising: {
            client: 'googima',
            omidSupport: 'auto',
            maxRedirects: 6,
            loadVideoTimeout: 9000,
            ...rules,
        },
    }
}

const processPlaylistAds = ({
    playlist,
    tag,
    isLivestream,
    ad,
    trackingData,
}: ProcessPlaylistAds): void => {
    playlist?.forEach(item => {
        const adConfig = configureAd(item?.meta?.ad ?? ad)
        if (adConfig?.prerollEnabled) {
            const preroll = checkShowAd('prerollEnabled', adConfig)
            const midroll = checkShowAd(isLivestream ? 'midrollEnabled' : 'adpodEnabled', adConfig)
            const videoLength = getVideoLength(item)
            const adsCount = getPrerollsCount({
                duration: videoLength,
                isLivestream,
            })
            let vastUrl = tag
            if (!isLivestream) {
                const targeting = createTargetingQueryParameter({
                    ad: adConfig,
                    trackingData: item.tracking ?? trackingData,
                })
                vastUrl = vastUrl!.replace('~sport1kvs~', targeting)
            }
            item.adschedule = `${vastUrl}&vid_d=${videoLength?.toString()}`.replace(
                '&cust_params=',
                `&cust_params=${encodeURIComponent(
                    `content_dur=${adsCount}&pre=${preroll}&mid=${midroll}&`
                )}`
            )
        }
    })
}

export const configureVast = ({
    ad,
    type,
    trackingData,
    playlist,
    callback,
}: ConfigureVastProps) => {
    ad = configureAd(ad)
    const isLivestream = type === 'liveStream'
    const shouldLoadAds =
        !isPureActive() &&
        Config.ADS_ACTIVE &&
        (ad?.prerollEnabled || ad?.midrollEnabled || (!isLivestream && playlist?.length))

    if (!shouldLoadAds) return callback({})

    getVastUrl({
        ad,
        trackingData,
        videozone: buildVideoZone(type),
        callback: tags => {
            const adConfig: VideoPlayerAdProps = createAdConfig(isLivestream)

            if (tags?.preroll) {
                processPlaylistAds({
                    playlist,
                    tag: tags.preroll,
                    isLivestream,
                    ad,
                    trackingData,
                })
            }

            callback(adConfig)
        },
    })
}
